



























  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
  import { CountryModel } from '@/store/location/types';
  import { AboutModel, ContentModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { State } from 'vuex-class';

  @Component({
    name: 'productKesehatan',
    components: {
      compareHealtProtection: () => import('@/components/formCompare/compareHealtProtection.vue'),
      productHelp: () => import('@/components/ProductHelp.vue'),
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
  })
  export default class productKesehatan extends Vue {
    @State('page_content') page_content!: ContentModel[];
    @State('abouts') abouts!: AboutModel[];

    public banerDefault = require('@/assets/images/banner/kesehatan.jpg');
    public get banner(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'kesehatan-banner')[0]?.image ?? null;
    }

    public get information(): ContentModel {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'kesehatan-information')[0] ?? new ContentModel();
    }

    public get aboutApp(): AboutModel {
      return this.abouts.filter((v) => v.id === 0)[0];
    }

    public get helpImg(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'kesehatan-help')[0]?.image ?? null;
    }

    public get listBannerInfo(): ContentModel[] {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.SLIDER && item.name === 'kesehatan-slider');
    }

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
